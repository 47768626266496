import { render, staticRenderFns } from "./AddSponsors.vue?vue&type=template&id=79a6a110&scoped=true&"
import script from "./AddSponsors.vue?vue&type=script&lang=js&"
export * from "./AddSponsors.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79a6a110",
  null
  
)

export default component.exports