<template>
  <div>
    <h2 class="col-lg-12 text-center mb-3">{{ ('Add sponsor') }}</h2>
      <FormSponsors @submit-requested="submitRequested"></FormSponsors>
  </div>
</template>
 
<script>
import { mapState, mapActions } from 'vuex'
import FormSponsors from '../forms/FormSponsors.vue'

  export default {
  components: { FormSponsors },
    methods: {
      ...mapActions('sponsors', ['addSponsors']),
      submitRequested (sponsorsInfo) {
        this.addSponsors(sponsorsInfo);
      },
    }
  }
</script> 
 
<style scoped>
</style> 